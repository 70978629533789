import { createSlice } from "@reduxjs/toolkit";
import { tweetsReducers } from "../reducers";

const initialState = {
  isLoading: false,
  list: [],
  lastUpdate: null,
};

const reducers = {
  settweetsLastUpdate(state, { payload }) {
    state.lastUpdate = payload;
  },
};

const tweetsSlice = createSlice({
  name: "tweets",
  initialState,
  reducers,
  extraReducers: tweetsReducers,
});

export const { settweetsLastUpdate } = tweetsSlice.actions;
export const tweets = tweetsSlice.reducer;
