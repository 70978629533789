export const REPORT_TYPES = {
  FOOTBALL: "Football",
  CONCERT: "Concert",
};

export const REPORTING_TYPE = {
  ownership: "ownership",
  duplicate: "duplicate",
  edit: "edit",
};

export const FOOTBALL_TYPES = {
  MEN: "Men's Football",
  WOMEN: "Women's Football",
};

export const COMPETITION_TYPE = {
  friendly: "Friendly Match",
};

export const SECURITY_LEVELS = ["High", "Medium", "Low", "Club Security", "Police Free", "Police Free +", "Police Spotters Only"];

export const ARCHIVED_SEASONS = ["2016/2017", "2017/2018", "2018/2019", "2019/2020", "2020/2021", "2021/2022", "2022/2023"];
