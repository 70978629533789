import { ALL_DROPDOWN_OPTION, COMPETITION_TYPE } from "shared/constants";

export const makeArrayToList = (list) => {
  if (!list) return [];
  return list?.map((n) => ({ label: n, value: n }));
};

export const addBaseDropdownOptionsSimple = (list) => {
  if (!list) return [ALL_DROPDOWN_OPTION];

  const merged = list.map((n) => ({ label: n, value: n }));
  return [ALL_DROPDOWN_OPTION, ...merged];
};

export const addBaseDropdownOptions = (list) => {
  if (!list) return [ALL_DROPDOWN_OPTION];
  return [ALL_DROPDOWN_OPTION, ...list];
};

export const addFriendlyMatch = (list) => {
  if (!list) return [{ label: COMPETITION_TYPE.friendly, value: COMPETITION_TYPE.friendly }];
  return [...list, { label: COMPETITION_TYPE.friendly, value: COMPETITION_TYPE.friendly }];
};

export const updateSeasonCompetition = (payload = {}, list = []) => {
  const updatedList = list?.map((existing) => {
    if (existing?.id === payload?.id) {
      return payload;
    }

    return existing;
  });

  return updatedList;
};

export const removeDuplicatesArray = (existing = [], newer = [], id = "id") => {
  const list = [...(existing || []), ...(newer || [])];
  let unique = [];

  list.forEach((x) => {
    const ex = unique.find((a) => a?.[id] === x?.[id]);
    if (!ex) {
      unique.push(x);
    }
  });

  return unique;
};
