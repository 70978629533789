import React from "react";
import Select from "react-select";
import { Label } from "components/typography";
import { multipleClassNames } from "shared/utilities";
import styles from "./ReactSelect.module.scss";

export const ReactSelect = ({ className = [], label, formik, name, options = [], disabled, ...props }) => {
  const onChange = (option) => {
    formik.setFieldValue(name, option.value);
    formik.setTouched(true);
  };

  return (
    <Label label={label} disabled={disabled}>
      <Select
        className={multipleClassNames([styles.reactSelect, ...className])}
        options={options}
        onChange={onChange}
        value={options ? options.find((option) => option.value === formik.values[name]) : ""}
        isDisabled={disabled}
        {...props}
      />
    </Label>
  );
};
