import React from "react";
import { Link } from "react-router-dom";
import { FaSortDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { ROUTES } from "shared/constants";
import { NAVIGATION_SMALL } from "shared/config";
import { getDisplayName, multipleClassNames } from "shared/utilities";

import { Button, BUTTON_COLORS } from "components/library";
import { setAccountDropdown } from "containers/AppStore/slices";
import { getUserData, signOutUser } from "containers/AppStore/reducers";

import styles from "./Account.module.scss";

export const Account = () => {
  const dispatch = useDispatch();
  const isActive = useSelector((store) => store.main.accountDropdown);
  const userData = useSelector((store) => store.user.data);
  const displayName = getDisplayName(userData);

  const handleLogout = () => {
    dispatch(signOutUser());
  };

  React.useEffect(() => {
    if (!userData) {
      dispatch(getUserData());
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.account}>
      <Button color={BUTTON_COLORS.link} className={[styles.account__user]} onClick={() => dispatch(setAccountDropdown())} data-target="menu">
        <img src="/img/user_default.jpg" alt="email" title="email" />
        <span>Me</span>
        <FaSortDown />
      </Button>
      <div className={isActive ? multipleClassNames([styles.account__menu, styles.active]) : styles.account__menu} data-target="menu">
        <Link to={ROUTES.dashboard} className={styles.account__profile}>
          <strong>{displayName}</strong>
          <img src="/img/user_default.jpg" alt="email" title="email" />
          {userData?.job_title && <span>{userData?.job_title}</span>}
        </Link>
        <div className={styles.account__category}>
          <span data-target="menu">Account</span>
          {NAVIGATION_SMALL.map((item) => (
            <Link key={item.route} to={item.route}>
              {item.name}
            </Link>
          ))}
        </div>

        <div className={styles.account__category}>
          <span data-target="menu">Services</span>
          <a target="_blank" rel="noreferrer" href="https://fsoa.org.uk/">
            Membership
          </a>
          <a target="_blank" rel="noreferrer" href="https://fsoa.org.uk/corporate-sponsors/">
            Partners
          </a>
          <a target="_blank" rel="noreferrer" href="https://fsoa.org.uk/corporate-sponsors/">
            Corporate Events
          </a>
          <a target="_blank" rel="noreferrer" href="https://fsoa.org.uk/fsoa-safety-officer-training/">
            Trainings
          </a>
        </div>

        <div className={styles.account__category}>
          <span data-target="menu">Legal</span>
          <a target="_blank" rel="noreferrer" href="https://fsoa.org.uk/privacy-policy/">
            Privacy Policy
          </a>
          <a target="_blank" rel="noreferrer" href="https://fsoa.org.uk/terms-of-use/">
            Terms of Use
          </a>
        </div>

        <div className={styles.account__category}>
          <span data-target="menu">Contact</span>
          <a target="_blank" rel="noreferrer" href="mailto:info@fsoa.org.uk">
            Email: info@fsoa.org.uk
          </a>
          <a target="_blank" rel="noreferrer" href="tel:01615219987">
            Phone: 0161 521 9987
          </a>
        </div>
        <Button onClick={handleLogout} color={BUTTON_COLORS.link}>
          Log out
        </Button>
      </div>
    </div>
  );
};
