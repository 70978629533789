import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaExternalLinkAlt, FaSignOutAlt, FaTh } from "react-icons/fa";

import { ROUTES } from "shared/constants";
import { getDisplayName } from "shared/utilities";
import { Button, BUTTON_COLORS } from "components/library";
import { signOutUser } from "containers/AppStore/reducers";
import { NAVIGATION, NAVIGATION_SMALL } from "shared/config";

import styles from "./Sidebar.module.scss";

export const Sidebar = () => {
  const [active, setActive] = React.useState(false);
  const userData = useSelector((store) => store.user.data);
  const fullname = getDisplayName(userData);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(signOutUser());
  };

  return (
    <div className={styles.sidebar}>
      <Button color={BUTTON_COLORS.link} onClick={() => setActive(!active)}>
        <FaTh />
      </Button>
      <div className={active ? styles.overlay : styles.hidden}>
        <div className={styles.overlay__content} onClick={() => setActive(!active)}>
          <div className={styles.content}>
            <div className={styles.nav}>
              <div className={styles.nav__user}>
                <Link to={ROUTES.dashboard}>
                  <span>{fullname}</span>
                  <img src="/img/user_default.jpg" alt="email" title="email" width={50} height={50} />
                </Link>
              </div>
              <div className={styles.nav__menu}>
                {[...NAVIGATION, ...NAVIGATION_SMALL].map((item) => (
                  <Link key={item.route} to={item.route}>
                    {item.name}
                  </Link>
                ))}
                <a href="https://fsoa.org.uk/members-area/" target="_blank" rel="noreferrer">
                  <FaExternalLinkAlt size={12} /> Members Area
                </a>
              </div>
              <Button className={[styles.nav__logout]} onClick={handleLogout} color={BUTTON_COLORS.link}>
                <FaSignOutAlt />
                Log out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
