import React from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./NavigationItem.module.scss";

export const NavigationItem = ({ to, name }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <li className={isActive ? styles.active : ""}>
      <Link className={styles.link} to={to}>
        {name}
      </Link>
    </li>
  );
};
