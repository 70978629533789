import { createSlice } from "@reduxjs/toolkit";
import { userReducers } from "../reducers";

const initialState = {
  isLoading: false,
  data: null,
  isAdmin: false,
};

const reducers = {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers,
  extraReducers: userReducers,
});

// export const {} = userSlice.actions;
export const user = userSlice.reducer;
