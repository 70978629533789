import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountDropdown: false,
  notifications: [],
};

const reducers = {
  resetMain(state) {
    state = initialState;
  },
  addNotifications(state, { payload }) {
    state.notifications = [...state.notifications, payload];
  },
  removeNotifications(state, { payload }) {
    state.notifications = state.notifications.filter((notification) => notification.id !== payload);
  },
  setAccountDropdown(state) {
    state.accountDropdown = !state.accountDropdown;
  },
};

const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers,
});

export const { resetMain, addNotifications, removeNotifications, setAccountDropdown } = mainSlice.actions;
export const main = mainSlice.reducer;
