import React from "react";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";

import { ROUTES } from "shared/constants";
import { NAVIGATION } from "shared/config";

import { Sidebar, Account } from "containers/Layout/components";
import { NavigationItem } from "../NavigationItem";

import styles from "./Header.module.scss";

export const Header = () => {
  return (
    <header className={styles.header}>
      <Link to={ROUTES.dashboard}>
        <picture className={styles.header__logo}>
          <source media="(min-width:768px)" srcSet="/img/logo.png" width={300} height={75} />
          <img src="/img/logo_icon.png" alt="Matchbox" title="Matchbox Reporting Tool" />
        </picture>
      </Link>

      <nav className={styles.navigation}>
        <ul>
          {NAVIGATION.map((item) => (
            <NavigationItem key={item.route} to={item.route} name={item.name} />
          ))}
          <li>
            <a className={styles.link} href="https://fsoa.org.uk/members" target="_blank" rel="noreferrer">
              <FaExternalLinkAlt size={12} /> Members Area
            </a>
          </li>
        </ul>
      </nav>

      <Account />
      <Sidebar />
    </header>
  );
};
