import React from "react";
import { useDispatch } from "react-redux";
import { FaInfo } from "react-icons/fa";
import { BiX } from "react-icons/bi";

import { multipleClassNames } from "shared/utilities";
import { Button, BUTTON_COLORS } from "components/library";
import { removeNotifications } from "containers/AppStore/slices";
import { NOTIFICATION_TYPE } from "../../../../shared/constants/notifications";

import styles from "./NotificationToast.module.scss";

export const NotificationToast = ({ id, text, type = NOTIFICATION_TYPE.success }) => {
  const dispatch = useDispatch();
  const style = [styles.notificationToast, styles[type]];
  const title = type === NOTIFICATION_TYPE.success ? "Success" : "Error";

  const handleOnClose = () => {
    dispatch(removeNotifications(id));
  };

  return (
    <div className={multipleClassNames(style)}>
      <FaInfo className={multipleClassNames([styles.notificationToast__icon, styles.notificationToast__info])} />
      <span>{title}</span>
      <span>{text}</span>
      <Button color={BUTTON_COLORS.link} className={[styles.notificationToast__close]} name="close" onClick={handleOnClose}>
        <BiX size={24} className={styles.notificationToast__icon} />
      </Button>
    </div>
  );
};
