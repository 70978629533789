import React from "react";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import LoadingPage from "pages/loading";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// SLICES
import { main, user, members, seasons, data, reports, tweets, duplicates, ownership, reporting } from "./slices";

// VERSION
import config from "../../../package.json";
const { version } = config;
export const key = `root-${version}`;

// PERSIST
const persistConfig = { key, storage, blacklist: ["data", "reports", "reporting", "members"] };

const reducer = combineReducers({
  main,
  user,
  members,
  seasons,
  data,
  reports,
  tweets,
  duplicates,
  ownership,
  reporting,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

const persistor = persistStore(store);

const AppStore = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingPage />} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default AppStore;
