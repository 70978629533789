export const multipleClassNames = (arr) => {
  return arr.join(" ");
};

export const getDisplayName = (data) => {
  if (!data) return "";
  return [data?.first_name, data?.last_name].join(" ");
};

export const trimStringToLength = (str, len) => {
  if (str?.length <= len) return str;
  return String(str).substring(0, len).concat("...");
};

export const sortStringsList = (list, key = "name") => {
  if (!list) return [];

  const copy = [...list];

  return copy.sort((a, b) => {
    const nameA = a[key]?.toLowerCase();
    const nameB = b[key]?.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const answerBoolean = (bool) => {
  return bool ? "Yes" : "No";
};

export const getStringTrimBySize = (size) => {
  switch (true) {
    case size < 350:
      return 15;
    case size < 1024:
      return 30;
    case size < 1200:
      return 15;
    case size < 1600:
      return 30;
    default:
      return 30;
  }
};

export const findStringInObject = (payload, keyword) => {
  const objectValues = Object.values(payload);

  return objectValues.some((n) => {
    if (typeof n === "string") {
      return n?.toLowerCase()?.includes(keyword?.toLowerCase());
    } else {
      return false;
    }
  });
};

export const swapCommaForDot = (string) => {
  if (typeof string === "string") {
    return String(string).replaceAll(",", ";").replaceAll("\n", "");
  }

  return string;
};

export const handleArrayToString = (data) => {
  if (typeof data === "string") {
    return data;
  }

  if (data?.length === 0) {
    return "";
  }

  return data?.join(", ");
};
