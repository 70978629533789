import React from "react";
import styles from "./LoadingWrapper.module.scss";

export const LoadingWrapper = () => {
  return (
    <div className={styles.loading}>
      <img src="/img/logo_icon.png" alt="loading icon" title="reporting tool loading" />
      <span>Loading...</span>
    </div>
  );
};
