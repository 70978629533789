import React from "react";
import { Label } from "components/typography";
import { multipleClassNames } from "shared/utilities";
import styles from "./Input.module.scss";

export const Input = ({ className = [], label, formik = {}, type = "text", name = "", disabled, ...props }) => {
  const style = [styles.input, ...className];
  const fieldValue = type === "date" ? formik.values[name].toString().split("/").reverse().join("-") : formik.values[name];

  return (
    <Label label={label} disabled={disabled}>
      <input
        className={multipleClassNames(style)}
        type={type}
        name={name}
        onChange={formik.handleChange}
        value={fieldValue}
        disabled={disabled}
        {...props}
        //
      />
    </Label>
  );
};
