import { nanoid } from "nanoid";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addDoc, collection, deleteDoc, doc, getDocs } from "firebase/firestore";

import { firestoreDB } from "shared/config";
import { addNotifications } from "../slices";
import { ENDPOINTS, NOTIFICATION_TYPE } from "shared/constants";

export const getReportings = createAsyncThunk("reporting/getReportings", async (payload, { dispatch }) => {
  try {
    const d = await getDocs(collection(firestoreDB, ENDPOINTS.reporting));
    const r = d.docs.map((doc) => ({ id: doc?.id, ...doc.data() }));
    return r;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Could not retrieve data. Try again later.", type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const addReporting = createAsyncThunk("reporting/addReporting", async (payload, { dispatch }) => {
  try {
    const d = await addDoc(collection(firestoreDB, ENDPOINTS.reporting), payload);
    dispatch(addNotifications({ id: nanoid(), text: "Completed. Thank you", type: NOTIFICATION_TYPE.success }));
    return { id: d?.id, ...payload };
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Failed action. Try again later.", type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const removeReporting = createAsyncThunk("reporting/removeReporting", async (payload, { dispatch }) => {
  try {
    const { id } = payload;
    await deleteDoc(doc(firestoreDB, ENDPOINTS.reporting, id));
    dispatch(addNotifications({ id: nanoid(), text: "Completed. Thank you", type: NOTIFICATION_TYPE.success }));
    return payload;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Failed action. Try again later.", type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const reportingReducers = (builder) => {
  builder.addCase(getReportings.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getReportings.fulfilled, (state, { payload }) => {
    state.list = payload;
    state.isLoading = false;
  });
  builder.addCase(getReportings.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(addReporting.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(addReporting.fulfilled, (state, { payload }) => {
    state.list = [...state?.list, payload];
    state.isLoading = false;
  });
  builder.addCase(addReporting.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(removeReporting.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(removeReporting.fulfilled, (state, { payload }) => {
    state.list = state.list?.filter((item) => item?.id !== payload?.id);
    state.isLoading = false;
  });
  builder.addCase(removeReporting.rejected, (state) => {
    state.isLoading = false;
  });
};
