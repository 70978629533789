import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoadingPage from "pages/loading";
import { ROUTES } from "shared/constants";
import AppStore from "containers/AppStore";
import SetPasswordPage from "pages/setPasswordPage";

const HomePage = lazy(() => import("pages/index"));
const LoginPage = lazy(() => import("pages/login"));
const SettingsPage = lazy(() => import("pages/settings"));
const AdminSettingsPage = lazy(() => import("pages/settings/admin"));
const MembersPage = lazy(() => import("pages/members"));
const ReportsPage = lazy(() => import("pages/reports"));
const ResetPasswordPage = lazy(() => import("pages/reset-password"));
const HelpPage = lazy(() => import("pages/help"));
const Error404Page = lazy(() => import("pages/404"));

const SingleReportPage = lazy(() => import("pages/reports/pid"));
const MemberPage = lazy(() => import("pages/members/pid"));

const FSOAPage = lazy(() => import("pages/data/organizations/fsoa"));
const ApostoPage = lazy(() => import("pages/data/organizations/aposto"));

const ClubsPage = lazy(() => import("pages/data/clubs"));
const AgenciesPage = lazy(() => import("pages/data/agencies"));
const SeasonsPage = lazy(() => import("pages/data/seasons"));
const VenuesPage = lazy(() => import("pages/data/venues"));

const AppRouter = () => {
  return (
    <AppStore>
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route index path={ROUTES.dashboard} element={<HomePage />} />
            <Route path={ROUTES.login} element={<LoginPage />} />
            <Route path={ROUTES.setPassword} element={<SetPasswordPage />} />
            <Route path={ROUTES.reset} element={<ResetPasswordPage />} />
            <Route path={ROUTES.settings} element={<SettingsPage />} />
            <Route path={ROUTES.adminSettings} element={<AdminSettingsPage />} />
            <Route path={ROUTES.members} element={<MembersPage />} />
            <Route path={`${ROUTES.members}/:pid`} element={<MemberPage />} />
            <Route path={ROUTES.reports} element={<ReportsPage />} />
            <Route path={`${ROUTES.reports}/:pid`} element={<SingleReportPage />} />
            <Route path={ROUTES.clubs} element={<ClubsPage />} />
            <Route path={ROUTES.agencies} element={<AgenciesPage />} />
            <Route path={ROUTES.venues} element={<VenuesPage />} />
            <Route path={ROUTES.seasons} element={<SeasonsPage />} />
            <Route path={ROUTES.help} element={<HelpPage />} />
            <Route path={ROUTES.fsoa} element={<FSOAPage />} />
            <Route path={ROUTES.aposto} element={<ApostoPage />} />
            <Route path="*" element={<Error404Page />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </AppStore>
  );
};

export default AppRouter;
