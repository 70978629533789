import { nanoid } from "nanoid";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, doc, getDocs, limit, orderBy, query, setDoc, startAfter, where } from "firebase/firestore";

import { firestoreDB } from "shared/config";
import { addNotifications } from "../slices";
import { removeDuplicatesArray } from "shared/utilities";
import { ENDPOINTS, NOTIFICATION_TYPE } from "shared/constants";

export const getWholeMembers = createAsyncThunk("users/getWholeMembers", async (payload, { dispatch }) => {
  try {
    const q = collection(firestoreDB, ENDPOINTS.users);
    const r = query(q, orderBy("uid"));
    const d = await getDocs(r);
    return {
      wholeList: d.docs.map((doc) => doc.data()),
    };
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: `Could not retrieve member's list. Check with your administrator`, type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const getMembers = createAsyncThunk("users/getMembers", async (payload, { dispatch }) => {
  try {
    // const size = 50;
    const q = collection(firestoreDB, ENDPOINTS.users);
    const r = query(q, orderBy("uid"));
    const d = await getDocs(r);
    return {
      lastVisible: d.docs?.at(-1),
      list: d.docs.map((doc) => doc.data()),
    };
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: `Could not retrieve member's list. Check with your administrator`, type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const getMoreMembers = createAsyncThunk("users/getMoreMembers", async (payload, { dispatch }) => {
  try {
    const q = collection(firestoreDB, ENDPOINTS.users);
    const r = query(q, orderBy("uid"), startAfter(payload), limit(50));
    const d = await getDocs(r);
    return {
      lastVisible: d.docs?.at(-1),
      list: d.docs.map((doc) => doc.data()),
    };
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: `Could not retrieve member's list. Check with your administrator`, type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const createMemberAccount = createAsyncThunk("users/createMemberAccount", async (payload, { dispatch }) => {
  try {
    await setDoc(doc(firestoreDB, ENDPOINTS.users, payload?.uid), payload);
    dispatch(addNotifications({ id: nanoid(), text: `${payload?.email}'s account created successfully`, type: NOTIFICATION_TYPE.success }));
    return payload;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: `Something went wrong. Check with your administrator`, type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const updateMemberAccount = createAsyncThunk("users/updateMemberAccount", async (payload, { dispatch }) => {
  try {
    await setDoc(doc(firestoreDB, ENDPOINTS.users, payload?.uid), payload, { merge: true });
    dispatch(addNotifications({ id: nanoid(), text: `${payload?.email}'s account updated successfully`, type: NOTIFICATION_TYPE.success }));
    return payload;
  } catch (error) {
    console.error(error);
    dispatch(
      addNotifications({ id: nanoid(), text: `Could not update account for ${payload?.email}. Check with your administrator`, type: NOTIFICATION_TYPE.error })
    );
    return null;
  }
});

export const getMembersByFilter = createAsyncThunk("users/getMembersByFilter", async (payload, { dispatch }) => {
  console.log("pay", payload);
  try {
    const q = collection(firestoreDB, ENDPOINTS.users);
    const arrayQuery = [];
    let nameSearch = false;

    if (payload?.oper && payload?.oper === "expired") {
      arrayQuery.push(where("membership_expiration_date", "<", payload?.timeNow));
    }

    if (payload?.oper && payload?.oper === "about_to_expire") {
      console.log("this one rnning");
      arrayQuery.push(where("membership_expiration_date", "<", payload?.timeNow + 2592000000), where("membership_expiration_date", ">", payload?.timeNow));
    }

    if (payload?.club) {
      arrayQuery.push(where("club", "==", payload?.club));
    }

    if (payload?.role) {
      arrayQuery.push(where("roles", "array-contains", payload?.role));
    }

    if (payload?.location) {
      arrayQuery.push(where("location", "==", payload?.location));
    }

    if (payload?.search) {
      if (payload?.search?.includes("@")) {
        arrayQuery.push(where("email", "==", payload?.search));
      } else {
        nameSearch = true;
      }
    }

    const r = query(q, ...arrayQuery);

    const d = await getDocs(r);
    let users = d.docs.map((doc) => doc.data());
    if (nameSearch) {
      users = users.filter((user) => {
        const firstName = user.first_name.toLowerCase();
        const lastName = user.last_name.toLowerCase();
        const [fName, lName] = payload.search.toLowerCase().split(" ");

        if (!lName || lName === "") {
          return firstName.includes(fName.trim()) || lastName.includes(fName.trim());
        }

        return firstName.includes(fName.trim()) && lastName.includes(lName.trim());
      });
    }

    return users;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: `Could not retrieve member's list. Check with your administrator`, type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const membersReducers = (builder) => {
  builder.addCase(getWholeMembers.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getWholeMembers.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.wholeList = removeDuplicatesArray(state.wholeList, payload?.wholeList, "uid");
  });
  builder.addCase(getWholeMembers.rejected, (state) => {
    state.isLoading = false;
  });
  builder.addCase(getMembers.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getMembers.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.list = removeDuplicatesArray(state.list, payload?.list, "uid");
    state.lastVisible = payload?.lastVisible;
    state.canLoadMore = payload?.list?.length === 50;
  });
  builder.addCase(getMembers.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(getMoreMembers.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getMoreMembers.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.list = removeDuplicatesArray(state.list, payload?.list, "uid");
    state.lastVisible = payload?.lastVisible;
    state.canLoadMore = payload?.list?.length === 50;
  });
  builder.addCase(getMoreMembers.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(getMembersByFilter.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getMembersByFilter.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.list = removeDuplicatesArray(state.list, payload, "uid");
    state.filteredList = payload;
    state.canLoadMore = false;
  });
  builder.addCase(getMembersByFilter.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(createMemberAccount.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(createMemberAccount.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.list = [...state.list, payload];
  });
  builder.addCase(createMemberAccount.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(updateMemberAccount.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(updateMemberAccount.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.list = state.list?.map((member) => (member?.uid === payload?.uid ? { ...member, ...payload } : member));
  });
  builder.addCase(updateMemberAccount.rejected, (state) => {
    state.isLoading = false;
  });
};
