import { createSlice } from "@reduxjs/toolkit";
import { ownershipReducers } from "../reducers";

const initialState = {
  isLoading: false,
  list: [],
  lastUpdate: null,
};

const reducers = {
  setOwnershipLastUpdate(state, { payload }) {
    state.lastUpdate = payload;
  },
};

const ownershipSlice = createSlice({
  name: "ownership",
  initialState,
  reducers,
  extraReducers: ownershipReducers,
});

export const { setOwnershipLastUpdate } = ownershipSlice.actions;
export const ownership = ownershipSlice.reducer;
