export const saveCache = (endpoint, obj) => {
  const existing = localStorage.getItem(endpoint);
  const date = new Date();

  if (existing) {
    localStorage.removeItem(endpoint);
  }

  obj.modified = date;

  localStorage.setItem(endpoint, obj);
  return;
};

export const getCache = (endpoint) => {
  const existing = localStorage.getItem(endpoint);

  if (!existing) {
    return false;
  }

  const parsed = JSON.parse(existing);
  return parsed;
};

export const clearLocalStorageFromOldKeys = (currentKey) => {
  const len = localStorage.length;

  for (let i = 0; i < len; i++) {
    const item = localStorage.key(i);

    if (item !== currentKey) {
      localStorage.removeItem(item);
    }
  }
};
