export const EXT_ROUTES = {
  login: "/login",
  reset: "/reset",
  setPassword: "/setPassword",
};

export const INT_ROUTES = {
  dashboard: "/",
  reports: "/reports",
  members: "/members",
  settings: "/settings",
  adminSettings: "/settings/admin",
  profile: "/profile",
  help: "/help",
  logout: "/logout",
  data: "/data",
};

export const ADMIN_ROUTES = {
  agencies: "/data/agencies",
  dogagencies: "/data/dogagencies",
  clubs: "/data/clubs",
  competitions: "/data/competitions",
  organizations: "/data/organizations",
  seasons: "/data/seasons",
  venues: "/data/venues",
};

export const CUSTOM_LANDING_ROUTES = {
  fsoa: "/data/organizations/fsoa",
  aposto: "/data/organizations/aposto",
};

export const ROUTES = {
  ...EXT_ROUTES,
  ...INT_ROUTES,
  ...ADMIN_ROUTES,
  ...CUSTOM_LANDING_ROUTES,
};
