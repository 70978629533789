import { createSlice } from "@reduxjs/toolkit";
import { reportingReducers } from "../reducers";

const initialState = {
  isLoading: false,
  list: [],
  lastUpdate: null,
};

const reducers = {
  setReportingLastUpdate(state, { payload }) {
    state.lastUpdate = payload;
  },
  addReportingsToList(state, { payload }) {
    state.list = [...state.list, ...payload];
  },
};

const reportingSlice = createSlice({
  name: "reporting",
  initialState,
  reducers,
  extraReducers: reportingReducers,
});

export const { setReportingLastUpdate, addReportingsToList } = reportingSlice.actions;
export const reporting = reportingSlice.reducer;
