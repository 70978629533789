import React from "react";
import { Label } from "components/typography";
import styles from "./Checkbox.module.scss";

export const Checkbox = ({ label, className = [], disabled, formik, name, ...props }) => {
  const style = [styles.formCheckbox, ...className];
  const value = formik.values[name];

  return (
    <Label label={label} disabled={disabled} className={[style]}>
      <input id={name} type="checkbox" disabled={disabled} onChange={formik.handleChange} checked={value} {...props} />
    </Label>
  );
};
