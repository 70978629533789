import axios from "axios";
import { BASE_URL } from "shared/constants";

const base = axios.create({ baseURL: BASE_URL, timeout: 60000 });

export const requestLocally = async (endpoint) => {
  try {
    const res = await base.get(`${endpoint}.json`);
    const { data } = res;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * This function transforms the local archived data in proper structure
 * @param {String} endpoint - "2022-2023"
 * @returns {Object}
 */
export const requestTransformedDataLocally = async (endpoint) => {
  try {
    const res = await base.get(`${endpoint}.json`);
    const { data } = res;
    let transformData = {};
    data.forEach(element => {
      let key = element.id;
      transformData[key] = element;
    });
    return transformData;
  } catch (error) {
    console.error(error);
    return null;
  }
};
