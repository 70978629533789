import { FOOTBALL_TYPES, REPORT_TYPES } from "shared/constants";

export const getReportTypeOptions = () => {
  const options = Object.values(REPORT_TYPES)?.map((n) => ({ label: n, value: n }));
  return options || [];
};

export const getFootballTypeOptions = () => {
  const options = Object.values(FOOTBALL_TYPES)?.map((n) => ({ label: n, value: n }));
  return options || [];
};

export const getSeasonsOptions = (list) => {
  if (!list) return [];

  const options = list?.map((n) => ({ label: n?.name, value: n?.name }));
  return options;
};

export const getCompetitionListFromSeason = (list, season) => {
  if (!list || !season) return [];

  const selectedSeason = list?.find((item) => item?.name === season);
  const competitions = selectedSeason?.competitions || [];
  const options = competitions?.map((n) => ({ label: n?.name, value: n?.name }));
  return options;
};

export const getAllCompetitionsFromSeasons = (list) => {
  if (!list) return [];

  const competitions = list?.map((season) => season?.competitions?.map((n) => n?.name) || []);
  const options = [...new Set(competitions.flat())];
  return options?.map((n) => ({ label: n, value: n }));
};

export const getClubsListFromCompetition = (list, season, competition) => {
  if (!list || !season || !competition) return [];

  const selectedSeason = list?.find((item) => item?.name === season);
  const competitions = selectedSeason?.competitions || [];
  const selectedCompetition = competitions?.find((item) => item?.name === competition);
  const options = selectedCompetition?.clubs?.map((n) => ({ label: n, value: n }));
  return options;
};

export const getPaginatedReports = ({ sortedList, page, size }) => {
  if (!sortedList) return [];
  const index = size * page;
  return sortedList?.slice(index, index + size);
};

export const getReportTypeTitle = (report) => {
  switch (report?.report_type?.toLowerCase()) {
    case REPORT_TYPES.FOOTBALL.toLowerCase():
      return `${report?.home_team} - ${report?.away_team}`;
    case REPORT_TYPES.CONCERT.toLowerCase():
      return report?.event_artist;
    default:
      return "-";
  }
};
