import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { removeNotifications } from "containers/AppStore/slices";
import { NotificationToast } from "../NotificationToast";

import styles from "./NotificationCard.module.scss";

export const NotificationCard = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((store) => store.main.notifications);
  const notificationRender = notifications?.map((notification) => <NotificationToast key={notification.id} {...notification} />);

  useEffect(() => {
    const interval = setInterval(() => {
      if (notifications?.length) {
        const [notification] = notifications;
        dispatch(removeNotifications(notification.id));
      }
    }, 15000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [notifications]);

  if (!notifications?.length) return <></>;

  return <div className={styles.notificationCard}>{notificationRender}</div>;
};
