import { createSlice } from "@reduxjs/toolkit";
import { membersReducers } from "../reducers";

const initialState = {
  isLoading: false,
  list: [],
  filteredList: [],
  filters: {
    search: "",
    club: "",
    role: "",
    location: "",
    oper: "",
  },
  lastVisible: null,
  lastUpdate: null,
  canLoadMore: false,
};

const reducers = {
  setMembersLastUpdate(state, { payload }) {
    state.lastUpdate = payload;
  },
  resetMembersPageFilters(state) {
    state.filters = initialState.filters;
    state.filteredList = initialState.filteredList;
  },
  updateMembersPageFilters(state, { payload }) {
    state.filters = {
      ...state.filters,
      ...payload,
    };
  },
};

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers,
  extraReducers: membersReducers,
});

export const { setMembersLastUpdate, resetMembersPageFilters, updateMembersPageFilters } = membersSlice.actions;
export const members = membersSlice.reducer;
