import { getFormatedDate } from "./date";
import { answerBoolean, handleArrayToString, swapCommaForDot } from "./strings";

export const buildFootballCSVObject = (reports) => {
  const headers = [
    "Season",
    "Competition/Event",
    "Teams/Artist",
    "Home Score",
    "Away Score",
    "Kickoff Date",
    "Kickoff Time",
    "Pre-match info",
    "Pre-match communication",
    "Police category",
    "Club category",
    "Club stewards (SIA)",
    "Club stewards (non-SIA)",
    "Visiting Club stewards",
    "Agencies",
    "Agency Stewards (SIA)",
    "Agency Stewards (non-SIA)",
    "Dog agencies",
    "Traffic Management Plan",
    "HVM",
    "Traffic Regulation Order",
    "Is there a fanzone in operation?",
    "Are visitors allowed in the fanzone?",
    "Are any club bars open to visitors?",
    "Home ticket sales",
    "Home attendance",
    "Home entry refusals",
    "Home ejections",
    "Home arrests",
    "Away ticket sales",
    "Away attendance",
    "Away entry refusals",
    "Away ejections",
    "Away arrests",
    "Any safeguarding issues?",
    "Any visitor departure issues?",
    "Coaches",
    "Minibuses",
    "Weather",
    "Medical Report",
    "Total number of incidents - spectators",
    "Incidents due to pre-existing conditions - spectators",
    "Total number of incidents - staff",
    "Incidents due to pre-existing conditions - staff",
    "Other comments",
  ].join(",");

  const reportsData = reports
    .map((report) => {
      return [
        report.season || "-",
        report.competition || "-", // check for event
        `${report.home_team} - ${report.away_team}` || "-", // check for artist
        String(report.home_result || 0),
        String(report.away_result || 0),
        getFormatedDate(report.event_date) || "-",
        report.event_time || "-",
        swapCommaForDot(report.pre_event_info) || "-",
        swapCommaForDot(report.pre_event_communication) || "-",
        report.police_security_category || "-",
        report.club_security_category || "-",
        swapCommaForDot(report.club_stewards_sia) || "-",
        swapCommaForDot(report.club_stewards) || "-",
        swapCommaForDot(report.club_stewards_visiting) || "-",
        report.agency_list?.join("; "),
        swapCommaForDot(report.agency_stewards_sia) || "-",
        swapCommaForDot(report.agency_stewards) || "-",
        swapCommaForDot(handleArrayToString(report.agency_list_dog)) || "-",
        swapCommaForDot(report.traffic_management_plan) || "-",
        answerBoolean(report.is_hmv),
        answerBoolean(report.traffic_regulation_order),
        answerBoolean(report.is_fanzone_operation),
        answerBoolean(report.is_fanzone_visitors_allowed),
        answerBoolean(report.is_clubs_open_visitors),
        report.home_ticket_sales || "-",
        report.home_attendance || "-",
        report.home_entry_refusals || "-",
        report.home_ejections || "-",
        report.home_arrests || "-",
        report.away_ticket_sales || "-",
        report.away_attendance || "-",
        report.away_entry_refusals || "-",
        report.away_ejections || "-",
        report.away_arrests || "-",
        answerBoolean(report.is_safeguarding_issues),
        answerBoolean(report.is_visitor_departure_issues),
        report.coaches || "-",
        report.minibuses || "-",
        swapCommaForDot(report.weather) || "-",
        swapCommaForDot(report.medical_report) || "-",
        swapCommaForDot(report.incidents_total_spectators) || "-",
        swapCommaForDot(report.incidents_pre_existing_spectators) || "-",
        swapCommaForDot(report.incidents_total_staff) || "-",
        swapCommaForDot(report.incidents_pre_existing_staff) || "-",
        swapCommaForDot(report.other_comments) || "-",
      ];
    })
    .map((z) => z.join(",").concat("\n"))
    .join("");

  const csvContent = `data:text/csv;charset=utf-8,${headers}\n${reportsData}`;
  return csvContent;
};

export const exportDataToCSV = (data) => {
  const timenow = new Date().toISOString();
  const encodedUri = encodeURI(data);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${String(timenow)}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
