import { createSlice } from "@reduxjs/toolkit";
import { reportsReducers } from "../reducers";
import { getDateForInputDate } from "shared/utilities";

const initialState = {
  isLoading: false,
  list: [],
  page: 0,
  pageSize: 10,
  filtered: [],
  filters: {
    type: "",
    home: "",
    away: "",
    season: "",
    fromdate: getDateForInputDate("01/01/2014"),
    todate: getDateForInputDate(),
    agency: "",
    keyword: "",
  },
  currentReport: null,
  lastUpdate: null,
};

const reducers = {
  setReportsLastUpdate(state, { payload }) {
    state.lastUpdate = payload;
  },
  resetCurrentReport(state) {
    state.currentReport = initialState.currentReport;
  },

  resetReportPageFilters(state) {
    Object.assign(state, initialState);
  },
  updateCurrentReport(state, { payload }) {
    state.currentReport = {
      ...state.currentReport,
      ...payload,
    };
  },
  updateReportPageFilters(state, { payload }) {
    state.filters = {
      ...state.filters,
      ...payload,
    };
    state.page = 0;
  },
  addReportsToList(state, { payload }) {
    state.list = [...state.list, ...payload];
  },
  updateReportsPage(state, { payload }) {
    const { page, pageSize } = payload;
    if (page >= 0) {
      state.page = page;
    }

    if (pageSize) {
      state.pageSize = pageSize;
    }
  },
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers,
  extraReducers: reportsReducers,
});

export const {
  setReportsLastUpdate,
  resetCurrentReport,
  updateCurrentReport,
  addReportsToList,
  resetReportPageFilters,
  updateReportPageFilters,
  updateReportsPage,
} = reportsSlice.actions;
export const reports = reportsSlice.reducer;
