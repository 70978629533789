import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { NotificationCard } from "containers/Layout";
import { Button, BUTTON_COLORS, ReactForm, Input } from "components/library";

import styles from "styles/pages/ResetPassword.module.scss";
import { auth } from "shared/config";
import { confirmPasswordReset } from "firebase/auth";
import { useFormik } from "formik";
import { object, string } from "yup";
import { nanoid } from "nanoid";
import { addNotifications } from "containers/AppStore/slices";
import { NOTIFICATION_TYPE, ROUTES } from "shared/constants";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { passCheck } from "shared/utilities/passCheck";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SetPasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);

  const [newPassword, setNewPassword] = useState("");

  const query = useQuery();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
    },
  });

  return (
    <>
      <NotificationCard />
      <div className={styles.login}>
        <img className={styles.login__logo} src="/img/logo.png" alt="fsoa main logo" />
        <ReactForm className={[styles.login__form]}>
          <Input
            label="Enter your password"
            type="text"
            name="password"
            onFocus={() => setFormTouched(true)}
            onChange={(e) => passCheck(e.target.value, setNewPassword, setFormTouched, setIsPasswordValid)}
            formik={formik}
          />
          {formTouched ? (
            <div className="password-check-container">
              <h4>Password must include at least</h4>
              <label className="password-check">
                <TiTick size={22} className="titick" /> <ImCross size={22} className="cross" />1 upper case letter
              </label>
              <label className="password-check">
                <TiTick size={22} className="titick" />
                <ImCross size={22} className="cross" /> 1 lower case letter
              </label>

              <label className="password-check">
                <TiTick size={22} className="titick" /> <ImCross size={22} className="cross" />1 special character
              </label>
              <label className="password-check">
                <TiTick size={22} className="titick" /> <ImCross size={22} className="cross" />1 number
              </label>
              <label className="password-check">
                <TiTick size={22} className="titick" /> <ImCross size={22} className="cross" />6 characters
              </label>
            </div>
          ) : (
            <></>
          )}

          {/* {!formTouched ? null : (
            <div style={{ color: isPasswordValid ? "green" : "white", display: "flex", alignItems: "center", gap: "10px" }}>
              Password must have min 6 characters {isPasswordValid ? <TiTick size={24} color="green" /> : <></>}
            </div>
          )} */}
          <Button
            disabled={!isPasswordValid}
            className={[styles.login__button]}
            onClick={async (e) => {
              e.preventDefault();
              await confirmPasswordReset(auth, query.get("oobCode"), newPassword)
                .then(() => {
                  dispatch(addNotifications({ id: nanoid(), text: "You have set your password Successfully", type: NOTIFICATION_TYPE.success }));
                  navigate(ROUTES.login);
                })
                .catch((err) => {
                  dispatch(addNotifications({ id: nanoid(), text: "Something went wrong", type: NOTIFICATION_TYPE.error }));
                });
            }}
            color={BUTTON_COLORS.success}
          >
            Save and Continue
          </Button>
        </ReactForm>
      </div>
    </>
  );
}
